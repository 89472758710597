.ant-btn-icon-only.ant-btn-lg {
  height: 2.5rem;
  font-size: 1rem;
  width: 2.5rem;
  border-radius: 1rem;
  svg {
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
.ant-btn {
  height: 2.5rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  svg {
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.ant-form-item-label > label {
  font-size: 1rem;
  height: 2rem;
}

.ant-form-item {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.ant-form-item-control-input {
  min-height: 2rem;
}

.ant-select-selection-item {
  line-height: 2rem;
}

.ant-switch {
  min-width: 2.75rem;
  height: 1.4rem;
  line-height: 1.4rem;
}

.ant-switch-handle {
  height: 1.2rem;
  width: 1.2rem;
  top: 0.1rem;
  left: 0.1rem;
  &:before {
    border-radius: 0.55rem;
  }
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 1.2rem - 0.1rem);
}

.ant-slider {
  height: 0.75rem;
  font-size: 1rem;
  padding: 0.25rem 0;
}

.ant-slider-step,
.ant-slider-rail,
.ant-slider-track {
  height: 0.3rem;
}

.ant-slider-handle {
  width: 1rem;
  height: 1rem;
  border-width: 0.15rem;
  border-radius: 0.5rem;
  margin-top: calc(-0.5rem + 0.15rem);
}

.ant-tooltip-inner {
  min-width: 2rem;
  min-height: 2rem;
  padding: 0.375rem 0.5rem;
  border-radius: 1rem;
}

.ant-tooltip {
  font-size: 1rem;
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  bottom: -0.45rem;
}

.ant-tooltip-arrow {
  width: 0.9rem;
  height: 0.9rem;
}

.ant-tooltip-arrow-content {
  width: 0.33rem;
  height: 0.33rem;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 2rem;
  border-radius: 1rem;
  padding: 0 0.75rem;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: calc(2rem - 2px);
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 1rem;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 2rem;
  font-size: 1rem;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
  line-height: 1.5rem;
  min-height: 1.5rem;
  padding: 0 0.5rem;
}

.ant-select-item {
  min-height: 1.5rem;
  line-height: 1.5rem;
  padding: 0 0.5rem;
}

.ant-select-tree,
.ant-select-item {
  font-size: 1rem;
}

.ant-select-tree-switcher {
  width: 1rem;
  line-height: 1.5rem;
}

.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  font-size: 1rem;
}

.ant-space-align-center {
  gap: 0.5rem !important;
}

.ant-result-title {
  font-size: 1.8rem;
  line-height: 1.8;
}

.ant-result-extra {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.ant-result-icon {
  margin-bottom: 1.5rem;
}

.ant-result-icon > .anticon {
  font-size: 5rem;
}