.goog-te-gadget-simple {
  border-radius: 1rem;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  text-align: center !important;
  width: 100%;
}

.goog-te-gadget-simple {
  font-size: 1rem !important;
}

.goog-te-gadget-icon {
  background-image: url(https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png) !important;
  background-size: cover;
  background-position: 0 !important;
  height: 1rem !important;
  width: 1rem !important;
  margin-left: 0.1rem !important;
  margin-right: 0.1rem !important;
}

.goog-te-combo, .goog-te-banner *, .goog-te-ftab *, .goog-te-menu *, .goog-te-menu2 *, .goog-te-balloon * {
  font-size: 0.75rem !important;
}