@import "~antd/dist/antd.less";
@import "./googleTranslate.less";
@import "./antd.less";

@mobile: ~"only screen and (max-width: 768px)";
@tablet: ~"only screen and (max-width: 1024px)";
@tv: ~"only screen and (min-width: 2880px)";

@footer-height: 4rem;

html {
  font-size: calc(var(--vh, 1vh) * 2);
}

body {
  top: 0px !important;
}

// ---------------------- Header component ----------------
.header {
  position: relative;
  background-color: #fff !important;
  padding: 0.5rem;
  height: 4rem;
  .back-button {
    position: absolute;
    top: 0;
    left: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    height: 100%;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .dr-logo {
    height: 100%;
    line-height: normal;
    display: flex;
    justify-content: center;
    img {
      height: 100%;
    }
  }
}

// ---------------------- Left sider component ----------------
.left-sider-container {
  width: 17rem !important;
  min-width: 17rem !important;
  max-width: 17rem !important;
  @media @mobile {
    display: none !important;
  }
}

.left-sider {
  margin: 0.75rem;
  padding: 0.75rem;
  border: 1px solid #000e16;
  border-radius: 1rem;
  opacity: 0;
  transition: 0.4s ease-in-out, transform 0.6s ease-in-out;
  transform: translateX(-100%);
  &.fade-in {
    opacity: 1;
    transform: none;
  }
}
.overflow {
  .ant-layout-sider-children {
    overflow: visible;
  }
}
.ant-layout-sider-children {
  overflow: hidden;
}

// ---------------------- Right sider component ----------------
.right-sider-container {
  width: 13rem !important;
  min-width: 13rem !important;
  max-width: 13rem !important;
  @media @mobile {
    display: none !important;
  }
}

.right-sider {
  margin: 0.75rem;
  padding: 0.75rem;
  border: 1px solid #000e16;
  border-radius: 1rem;
  transition: 0.4s ease-in-out, transform 0.6s ease-in-out;
  transform: translateX(calc(100% + 1.5rem));
  &.fade-in {
    opacity: 1;
    transform: none;
  }
}

.not-found {
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
}

// ---------------------- LOGIN container ----------------
.login-container {
  min-height: calc(var(--vh, 1vh) * 100);
  background-image: url("https://colorlib.com/etc/lf/Login_v3/images/bg-01.jpg");
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
  }
  form {
    width: 500px;
    border-radius: 10px;
    overflow: hidden;
    padding: 55px;
    background: linear-gradient(to bottom, #2fa7de, #b224ef);
    .logo {
      width: 120px;
      margin: 0 auto;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .title {
      text-align: center;
      font-size: 30px;
      color: #fff;
      line-height: 1.2;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .button-container {
      display: flex;
      width: 100%;
      justify-content: center;
      .login-button {
        width: 95px;
      }
    }
    .forgot-password {
      padding-top: 50px;
      text-align: center;
      a {
        color: #fff;
        :hover {
          color: #2fa7de;
        }
      }
    }
  }
}

// ---------------------- Main container ----------------
.main-container {
  position: relative;
  height: calc(calc(var(--vh, 1vh) * 100) - @footer-height - @footer-height);
  .tour-guide {
    background-color: #fff;
    width: 100%;
    height: 100%;
    .status-pos {
      position: absolute;
      top: 0.75rem;
      left: 0.75rem;
      z-index: 2;
    }
    .status {
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2rem;
      width: 8rem;
      font-size: 1rem;
      border-radius: 0.25rem;
    }

    .tour-guide-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .viewers-count-pos {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
    }
    .viewers-count {
      border-radius: 0.25rem;
      background-color: rgba(0, 0, 0, 0.6);
      height: 2rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      color: #ffffff;
      display: flex;
      align-items: center;
      font-size: 1rem;
      svg {
        margin-right: 0.33rem;
      }
    }
    .transcript {
      position: absolute;
      bottom: 60px;
      font-size: 24px;
      color: #fff;
      line-height: 1.5em;
      height: 3em; /* height is 2x line-height, so two lines will display */
      overflow: hidden;
      padding: 12px;
    }
    .transcript-box {
      position: absolute;
      bottom: 0;
      width: calc(100% - 24px);
    }
    .fullscreen-button-pos {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
    }
    .fullscreen-button {
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      padding: 0.33rem 0;
      border: none !important;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.sign-interpreter {
  position: absolute;
  top: 0;
  right: 0;
  #sign-interpreter-video {
    width: 240px;
    height: 180px;
  }
}

// ---------------------- Status component ----------------
.PROCESSING {
  border: 1px #ff8800 solid;
  color: #ff8800;
}

.READY {
  border: 1px #555555 solid;
  color: #555555;
}

.ERROR {
  border: 1px #cc3333 solid;
  color: #cc3333;
}

.footer {
  position: relative;
  height: @footer-height;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}

.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-settings-button {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: currentColor;
    margin-right: 0.33rem;
  }
}
.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  svg {
    fill: currentColor;
    margin-right: 0.33rem;
  }
}

// ---------------------- Footer buttons ----------------
.mute-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  height: 2.5rem;
  border-radius: 1rem;
  svg {
    fill: currentColor;
    font-size: 1.5rem !important;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.stream-button {
  min-width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  height: 2.5rem;
  border-radius: 1rem;
  svg {
    fill: currentColor;
    margin-right: 0.33rem;
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.settings-button {
  display: flex;
  align-items: center;
  height: 2.5rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  svg {
    fill: currentColor;
    margin-right: 0.33rem;
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.settings-button-lg {
  .settings-button();
  @media @tablet {
    display: none;
  }
}

.settings-button-sm {
  .settings-button();
  display: none;
  @media @tablet {
    display: flex;
  }
}

.center {
  text-align: center;
}

.dr-home {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .dr-home-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    font-size: 1.5rem;
  }
}

.no-video-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("../assets/waveBack.png");
  background-repeat: no-repeat, repeat;
  background-position: center !important;
  .no-video-top {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    );
  }
  .no-video-bottom {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    );
  }
  .ant-avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6rem;
    height: 6rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    svg {
      width: 100%;
      height: 100%;
      fill: #2fa7de;
    }
  }
}

.copy-link {
  .ant-typography-copy {
    display: flex !important;
    .copy-link-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 0.33rem;
      }
    }
  }
}

.github-picker {
  span {
    div {
      width: 1.7rem !important;
      height: 1.7rem !important;
    }
  }
}

@primary-color: #2FA7DE;@border-radius-base: 15px;